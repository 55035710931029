import React from "react"
import Layout from "../layouts/de"
import HomeContent from "../components/HomeContent"
import SEO from "../components/SEO"

const IndexPage = props => (
  <Layout location={props.location}>
    <SEO title="Monika Martinková - Übersetzungsdienste" lang="de"/>
    <HomeContent />
  </Layout>
)

export default IndexPage
