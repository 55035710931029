module.exports = {
  'Jazyk': 'de',
  'selectLanguage': 'Wählen Sie die Sprache',
  'offering.heading': 'Was kann ich anbieten?',
  'header.name': 'Monika Martinková',
  'header.tagline': 'Gerichtsübersetzer für die tschechische und die deutsche und englische Sprache',
  'home.intro1': 'Ich biete meine langjährige Erfahrungen an, die ich bei der Übersetzung von Zehntausenden der Seiten erworben habe. Nach der letzten von der Europäischen Kommission ausgestellten Bescheinigung habe ich nur für diese Behörde <strong> 58&nbsp;000 Seiten</strong> übersetzt, das entspricht ungefähr <strong>260 Büchern</strong>. Weitere Tausende von Seiten habe ich für andere Institutionen oder Unternehmen im privaten sowie auch im&nbsp;öffentlichen Sektor übersetzt.',
  'home.intro2': 'Im Vergleich zu den Übersetzungsbüros bin ich in der Lage, im Preiswettbewerb zu bestehen und gleichzeitig den gesamten Übersetzungsprozess einschließlich Korrekturlesen und stilistischer Anpassungen zu sichern. Dies gewährleistet <strong> Konsistenz </strong> des gesamten Textes und <strong>sorgfältige Verwendung von Begriffen</strong>. Die Übersetzungen sind immer in der vereinbarten Frist abgegeben.',
  'offering.content': 'Ich biete schriftliche Übersetzungen (nicht Doltmetschen) an, und zwar aus dem <strong>Englischen</strong>, <strong>Deutschen</strong>, <strong>Polnischen</strong> und <strong>Slowakischen</strong> ins <strong>Tschechische</strong> und aus dem <strong>Tschechischen</strong> ins <strong>Englische</strong> und <strong>Deutsche</strong>.',
  'offering.legal': 'Ich biete beglaubigten Übersetzungen an. Ich biete auch Korrekturlesen und Textrevisionen sowie stilistische Anpassungen an.',
  'offering.tools': 'Ich verwende CAT-Tools (Computer Aided Translation) - zur Zeit <strong>SDL Trados Studio 2019</strong>.',
  'about.title': 'Über mich',
  'about.part1': 'Mit den Übersetzungen fing ich Anfang der neunziger Jahre in einem Unternehmen an, in den ich nach der Hoschule eintrat. Zu dieser Zeit begannen sich Geschäftsbeziehungen mit den ausländischen Lieferanten zu entwickeln und die ersten aus den EU-Mitteln finanzierten Verträge erschienen. Weil ich die Staatsprüfungen abgelegt hatte, war ich für das Übersetzen und Dolmetschen bei den Geschäftstreffen verantwortlich. Nach dem Mutterschaftsurlaub gewährleistete ich weiter die Übersetzungen für meinen früheren Arbeitsgeber und allmählich begann ich die Dienstleistungen in diesem Gebiet auch für andere Kunden zu erbringen.',
  'about.part2': 'Wegen dem großen Interesse um die Übersetzungen entschied ich mich, nach meinem Mutterschaftsurlaub als freiberufliche Übersetzerin zu arbeiten, und seit 1995 habe ich eine Gewerbeberechtigung.',
  'about.part3': 'Ende der neunziger Jahre wurde ich von einer tschechischen Agentur angesprochen, die vor dem Beitritt der Tschechischen Republik zur Europäischen Union eine Ausschreibung für Übersetzungen des EU-Rechts gewonnen hatte, um an der Erstellung tschechischer Übersetzungen Teil zu nehmen. Mit dieser Agentur arbeitete ich dann bis 2003 zusammen. In diesem Jahr wurden die ersten Wettbewerbe der EU-Institutionen ausgeschrieben, an denen ich mich aufgrund meiner Erfahrungen entschied Teil zu nehmen. Seitdem arbeite ich fast ausschließlich für die EU-Institutionen, insbesondere für die Europäische Kommission.',
  'about.part4': 'In dem Jahre 2018 wandte sich die OECD (Organisation für wirtschaftliche Zusammenarbeit und Entwicklung) mit einem Kooperationsangebot an mich, mit der ich dann einen Vertrag über die Erbringung von Übersetzungsdiensten abschloss.',
  'about.part5': 'Und ich habe kürzlich einen Vertrag mit dem Verlag Jan Melvil Publishing unterzeichnet, um mein erstes Buch zu übersetzen, was ist eine Arbeit, auf die ich mich sehr freue, weil sie einen ganz anderen Ansatz und andere Fähigkeiten erfordert.',
  'references.title': 'Referenz',
  'references.eu.title': 'Generaldirektion Übersetzung der Europäischen Kommission',
  'references.eu.part1': 'Diese Übersetzung wurde als "sehr gut" bewertet. Vielen Dank für Ihre qualitativ hochwertige Arbeit.',
  'references.eu.part2': 'Vielen Dank für Ihre Übersetzung, mit der wir sehr zufrieden waren. Insbesondere schätzen wir die einheitlichen Zitate aus den einschlägigen Urteilen des Gerichtshofs, Verordnungen usw. sowie Ihre Interpretationsfähigkeit.',
  'references.eu.part3': 'Wir betrachten (Ihre Übersetzung) als sehr präzise und schätzen das stilistische Niveau, die Richtigkeit und Genauigkeit der Zitate aus den Urteilen des Gerichtshofs und Erhaltung der im Text enthaltenen Anweisungen.',
  'references.eu.part4': 'Bescheinigung über die Anzahl der für die Europäische Kommission übersetzten Seiten – ca. <strong>58&nbsp;000&nbsp;Seiten</strong>, <strong>98&nbsp;%</strong> Übersetzungen &nbsp;bewertet als <strong>sehr gut&nbsp;/&nbsp;gut</strong>',
  'references.presto.title': 'Übersetzungszentrum Presto, Prag',
  'references.presto.part1': 'Presto nutzt die Übersetzungsfähigkeiten von Ing. Monika Martinková seit vielen Jahren, und in den letzten zwei Jahren war sie eine aus den Übersetzern, die sich an der Übersetzung der Rechtsvorschriften des Europäischen Gemeinschafts für das Amt der Regierung der Tschechischen Republik beteiligten. Ihre Übersetzungen aus dem Englischen und ins Englische sind auf einem sehr hohen Niveau. (&hellip;)',
  'references.ingstav.title': 'Ingstav Ostrava, a.s.',
  'references.ingstav.part1': 'Die Übersetzungen wurden immer in sehr hoher Qualität und schnell durchgeführt.',
  'references.air.title': 'AIR-nemovitosti, Praha',
  'references.air.part1': 'Unsere Firma arbeitet seit mehreren Jahren mit Ing. Martinkova sehr erfolgreich zusammen. Unsere Kunden wie HVB, ErsteBank oder Deutsche Bank sind mit den Übersetzungen zufrieden. Was die Termine trifft, immer zum vereinbarten Zeitpunkt.',
  'references.ci.title': 'Czech International, a.s., Havířov',
  'references.ci.part1': 'Frau Martinková arbeitet immer sehr flexibel und mit sehr präziser Fachterminologie. Daher können wir ihre professionellen Dienstleistungen empfehlen.',
  'price.title': 'Was ist der Preis?',
  'price.content': 'Der Preis hängt immer von der Zielsprache, der Schwierigkeit des Textes, dem Erstellungsdatum und dem Umfang ab (bei größeren Texten oder langfristiger Zusammenarbeit gibt es die Möglichkeit von Rabatten).',
  'time.title': 'Wann wird die Übersetzung fertig sein?',
  'time.content': 'Was die Frist für die Abgabe der Übersetzung trifft, ich versuche immer, die Wünsche des Kunden zu erfüllen, wobei ich natürlich den Umfang der Übersetzung und meine Kapazität berücksichtige. Das vereinbarte Lieferdatum halte ich <strong>immer streng</strong> ein.',
  'specialization.title': 'Meine Spezialisierung',
  'specialization.law': 'Recht (insbesondere Handelsrecht und Unionsrecht)',
  'specialization.economy': 'Alle Wirtschaftsfelder',
  'specialization.consumer': 'Verbraucherangelegenheiten',
  'specialization.environment': 'Umwelt',
  'specialization.social': 'Sozialer Bereich',
  'specialization.humanities': 'Geistes- und Sozialwissenschaften',
  'specialization.technical': 'Technische Texte',
  'specialization.press': 'Presseartikel',
  'specialization.correspondence': 'Korrespondenz',
  'education.title': 'Meine Ausbildung',
  'education.vsb': '<strong>Ingenieur der Ökonomie</strong> - Vysoká škola báňská - Technická univerzita v&nbsp;Ostravě (VSB Technische Universität Ostrava)',
  'education.cuni': '<strong>Absolvierung des Juridikums</strong> - an der Rechtsfakultät der Karlsuniversität, Prag',
  'education.llm': '<strong>Executive Master of Laws \u0013 Commercial Law</strong> \u0013 Handelsrecht im Kontext des Unionrechts',
  'contact.title': 'Kontakt',
  'contact.email': 'martinkova@translation-service.cz',
  'contact.mobile': 'Handy: +420&nbsp;777&nbsp;157&nbsp;503',
  'contact.tel': 'Telefonnummer: +420&nbsp;596&nbsp;410&nbsp;709',
  'contact.address': 'Monika Martinková<br />Vrchlického 6/1484<br />Havířov PSČ 736 01',
  'contact.ic': 'ID: 60959495',
  'contact.tradeId': 'Gewerbescheinnummer: 99/637556/3169/Kr'
}
